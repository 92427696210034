import type { ConcreteControlRequest, SerieRequest } from "~/types/requests";
import type {
  ConcreteSerie,
  Pagenated,
  ConcreteControl,
  Contract,
  ConcreteTestingBody,
  Application,
} from "~/types";
import * as ls from "./localstorage";

let slug = "";
export function setSlug(value: string) {
  slug = value;
}

let token = "";
let host = "";

export function authenticate(_token: string, _host: string) {
  token = "Bearer " + _token;
  host = _host;
}

type QueryString = {
  [key: string]: (string | number)[] | string | number | boolean;
};

export function queryString(params: QueryString) {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value !== null && value !== undefined) {
      searchParams.append(key, `${params[key]}`);
    }
  });

  const qs = searchParams.toString();

  return qs ? `?${qs}` : "";
}

async function handleResponse(response: Response) {
  let json;

  try {
    json = await response.json();
  } catch (e) {
    return Promise.reject(e);
  }

  if (response.ok) {
    return json;
  } else {
    return Promise.reject({
      status: response.status,
      error: json,
    });
  }
}

async function get<T = any>(path: string, query?: QueryString): Promise<T> {
  let qs = "";
  if (query) {
    qs = queryString(query);
  }

  const url = new URL(path, host);

  const requestOptions = {
    method: "GET",
    query,
    headers: {
      Authorization: token,
    },
  };

  return fetch(url.href + qs, requestOptions).then((r) => {
    if (r.status === 403) {
      ls.token.delete();
    }
    return handleResponse(r);
  });
}

async function post<T = any>(url: string, data?: any): Promise<T> {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

async function put<T = any>(url: string, data?: any): Promise<T> {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

export async function getConcreteControlById(contractId: number) {
  const url = `/${slug}/concretes/${contractId}/`;
  return get<ConcreteControl>(url);
}

export async function getConcreteControl(contractId: number) {
  const url = `/${slug}/concretes/controls/${contractId}/`;
  return get<ConcreteControl[]>(url);
}

export async function getSeries(controlId: number, options: { page: number }) {
  const url = `/${slug}/concretes/${controlId}/series_new/`;

  return get<Pagenated<ConcreteSerie>>(url, options);
}

export async function getSerie(id: number) {
  const url = `/${slug}/concretes/series-new/${id}/`;
  return get(url);
}

export async function getCPs(id: number) {
  const url = `/${slug}/concretes/series/${id}/cps/`;
  return get(url);
}

export async function getClients() {
  const url = `/${slug}/clients/?group=concrete&active=1&department=&fields=id,name`;
  return get(url);
}

export async function getWorks(clientId: number) {
  const url = `/${slug}/contracts/?sub_department=&client=${clientId}&work=&fields=&group=concrete&active=1&department=&active_at=`;
  return get<Contract[]>(url);
}

export async function postSeries(id: number, data: SerieRequest) {
  const endpoint = `${host}/${slug}/concretes/${id}/series/`;

  const req = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });

  return await req.json();
}

export async function putSeries(id: number, data: SerieRequest) {
  const endpoint = `${host}/${slug}/concretes/series/${id}/`;

  const req = await fetch(endpoint, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  });

  return await req.json();
}

export function postControl(contractId: number, data: any) {
  const url = `${host}/${slug}/concretes/controls/${contractId}/`;

  return post<ConcreteControl>(url, data);
}

export function putControl(controlId: number, data: ConcreteControlRequest) {
  const url = `${host}/${slug}/concretes/${controlId}/`;

  return put<ConcreteControl>(url, data);
}

export function getContract(contractId: number) {
  const url = `/${slug}/contracts/${contractId}/`;

  return get<Contract>(url);
}

export function searchCP(code: number) {
  const url = `/${slug}/concretes/series/cps/`;

  return get<ConcreteTestingBody[]>(url, { code });
}

export function updateCP(id: number, data: ConcreteTestingBody) {
  const url = `${host}/${slug}/concretes/series/cps/${id}/`;

  return put<ConcreteTestingBody>(url, data);
}

export function applications() {
  const url = "/apps/";
  return get<Application[]>(url);
}
