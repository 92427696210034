import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) => [
    {
      name: "start",
      path: "/",
    },
    {
      name: "login",
      path: "/login/",
      component: () => import("~/pages/login.vue").then((r) => r.default || r),
      meta: {
        layout: "none",
      },
    },
    {
      name: "home",
      path: "/:slug/concrete/:controlId(\\d*)?/",
      component: () => import("~/pages/index.vue").then((r) => r.default || r),
      meta: {
        key: "concrete",
      },
    },
    {
      name: "control",
      path: "/:slug/concrete/:contractId(\\d+)/control/:controlId(\\d*)/",
      component: () =>
        import("~/pages/edit_control.vue").then((r) => r.default || r),
    },
    {
      name: "serie",
      path: "/:slug/concrete/:controlId(\\d+)/serie/:serieId(\\d*)/",
      component: () => import("~/pages/serie.vue").then((r) => r.default || r),
    },
    {
      name: "cp",
      path: "/:slug/concrete/:controlId(\\d+)/cp/:cpId",
      component: () => import("~/pages/cp.vue").then((r) => r.default || r),
    },
    // {
    //   name: "break",
    //   path: "/:slug/control/:id(\\d+)/break/",
    //   component: () => import("~/pages/break.vue").then((r) => r.default || r),
    // },
  ],
};
